import {useContext, useRef} from 'react'
import {css} from '@emotion/react'
import {Modal as AntdModal} from 'antd'
import usePointer from '@/hooks/usePointer.mjs'
import {ModalManagerContext} from './ModalManager.jsx'

const cssModal = css({
    display: 'flex',
    flexDirection: 'column',
    top: '10vh',
    left: 0,
    right: 0,
    maxHeight: '80vh',
    paddingBottom: 0,
    margin: 'auto',
    overflow: 'hidden',
    boxShadow: '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',

    '& .ant-modal-content': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },

    '& .ant-modal-header': {
        padding: 0,
    },

    '& .ant-modal-body': {
        flexGrow: 1,
        padding: '10px 16px',
        overflow: 'auto',
    },

    '& .ant-modal-close-x': {
        width: '34px',
        height: '34px',
        lineHeight: '34px',
    },
})

const cssTitle = ({
    fontSize: 14,
    lineHeight: 1,
    padding: '10px 16px',
    userSelect: 'none',
})

const defaultButtonProps = {size: 'small'}

const Modal = ({
    style,
    draggable = true,
    height,
    ...props
}) => {
    const refElTitle = useRef()
    const refIsFocused = useRef(false)
    const refPos = useRef([0, 0])

    const hideWindow = () => {
        const elContent = refElTitle.current.closest('.ant-modal-content')
        elContent.style.opacity = 0
    }

    const showWindow = () => {
        const elContent = refElTitle.current.closest('.ant-modal-content')
        elContent.style.opacity = 100
    }

    const pointerEventHandlers = usePointer({
        onPointerDown: e => {
            refIsFocused.current = true

            if (e.ctrlKey) {
                hideWindow()
            }
        },

        onPointerUp: e => {
            refIsFocused.current = false

            if (e.ctrlKey) {
                showWindow()
            }
        },

        onSwipeMove: e => {
            if (1 !== e.buttons) {
                return
            }

            const elModal = refElTitle.current.closest('.ant-modal')
            const pos = refPos.current
            pos[0] += e.movementX
            pos[1] += e.movementY
            elModal.style.transform = `translate(${pos[0]}px, ${pos[1]}px)`
        },
    })

    const handleKeyDown = e => {
        if (
            refIsFocused.current &&
            'Control' === e.key
        ) {
            hideWindow()
        }
    }

    const handleKeyUp = e => {
        if (
            refIsFocused.current &&
            'Control' === e.key
        ) {
            showWindow()
        }
    }

    const closable = props.closable ?? false
    const styleModal = {...style, height}

    const styleTitle = {
        marginRight: closable ? 56 : 0,
        cursor: draggable ? 'move' : '',
    }

    const title = (
        <div
            ref={refElTitle}
            css={cssTitle}
            style={styleTitle}
            tabIndex="-1"
            {...(draggable ? pointerEventHandlers : null)}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
        >
            {props.title}
        </div>
    )

    const cancelButtonProps = {
        ...defaultButtonProps,
        ...props.cancelButtonProps,
    }

    const okButtonProps = {
        ...defaultButtonProps,
        ...props.okButtonProps,
    }

    return (
        <AntdModal
            css={cssModal}
            mask={false}
            maskClosable={false}
            {...props}
            style={styleModal}
            cancelButtonProps={cancelButtonProps}
            closable={closable}
            okButtonProps={okButtonProps}
            title={title}
        />
    )
}

const {
    confirm,
    error,
    info,
    success,
    warning,
} = AntdModal

Object.assign(Modal, {
    confirm,
    error,
    info,
    success,
    warning,
})

export default Modal

export const useOpenModal = () => useContext(ModalManagerContext)
export {default as Alert} from './Alert.jsx'
export {default as Confirm} from './Confirm.jsx'
export {default as Prompt} from './Prompt.jsx'
