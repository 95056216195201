import {useEffect, useState} from 'react'
import CustomError from '@/script/CustomError.mjs'
import {sharedEventBus} from '@/script/sharedEventBus.mjs'
import {request} from './http.mjs'

const KEY = 'token'

const load = () => {
    const item = window.localStorage.getItem(KEY)

    if (! item) {
        return null
    }

    try {
        return JSON.parse(item)
    }
    catch (err) {
        console.err(err)
        return null
    }
}

const save = token => {
    if (token) {
        const item = JSON.stringify(token)
        window.localStorage.setItem(KEY, item)
    }
    else {
        window.localStorage.removeItem(KEY)
    }
}

export let token = load()

sharedEventBus.subscribe(KEY, e => token = e.data)

export const setToken = newToken => {
    if (newToken === token) {
        return
    }

    token = newToken
    save(token)
    sharedEventBus.publish(KEY, token)
}

export const useToken = () => {
    const [, setFlag] = useState(false)

    useEffect(
        () => {
            const handleToken = () => setFlag(f => ! f)
            sharedEventBus.subscribe(KEY, handleToken)
            return () => sharedEventBus.unsubscribe(KEY, handleToken)
        },

        []
    )

    return token
}

export class RefreshTokenError extends CustomError {}

let isRefreshingToken = false
let promiseRefreshToken = Promise.resolve()

export const refreshToken = async () => {
    const {promise, reject, resolve} = Promise.withResolvers()

    const doRefresh = async ({token, refreshToken}) => request({
        headers: {__TOKEN__: token},
        method: 'POST',
        url: '/login/token/refresh',
        payload: {token, refreshToken},
    })

    try {
        isRefreshingToken = true
        promiseRefreshToken = promise
        const newToken = await doRefresh(token)
        setToken(newToken)
        resolve(newToken)
    }
    catch (err) {
        reject(new RefreshTokenError(err.message))
    }
    finally {
        isRefreshingToken = false
    }
}

export const waitForToken = () => {
    if (isRefreshingToken) {
        return promiseRefreshToken
    }
    else {
        return token
    }
}
