import {useRef, useState} from 'react'

export default (init, controlledState, setControlledState) => {
    const [state, setState] = useState(init)
    const refState = useRef(state)
    const isControlled = void 0 !== controlledState

    if (isControlled) {
        refState.current = controlledState
    }

    return [
        refState.current,

        x => {
            if ('function' === typeof x) {
                refState.current = x(refState.current)
            }
            else {
                refState.current = x
            }

            if (! isControlled) {
                setState(refState.current)
            }

            setControlledState(refState.current)
        }
    ]
}
