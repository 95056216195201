import ServerError from '@/script/ServerError.mjs'
import SuspenseError from '@/script/SuspenseError.mjs'
import httpRequest from '@/script/http.mjs'
import normalizeUrl from '@/script/normalizeUrl.mjs'
import {refreshToken, RefreshTokenError, token, waitForToken} from './token.mjs'
import {logout} from './user.mjs'

const doFetch = ({url, ...options}) => httpRequest({
    url: normalizeUrl(url),
    ...options,
})

export const request = async function(options) {
    const rsp = await doFetch(options)

    if (rsp.code) {
        const {code, errorBody, message, responseBody} = rsp

        if ('00000000' !== code) {
            throw new ServerError({code, detail: errorBody, message})
        }

        return responseBody
    }
    else {
        return rsp
    }
}

export const requestStream = async function*(options) {
    for await (const rsp of doFetch(options)) {
        if ('object' === typeof rsp) {
            const {code, errorBody, message} = rsp

            if ('00000000' !== code) {
                throw new ServerError({code, detail: errorBody, message})
            }
            else {
                throw new Error('not a streaming response')
            }
        }
        else {
            yield rsp
        }
    }
}

const withToken = async function(fn) {
    try {
        await waitForToken()
        const tokenBound = token?.token

        try {
            return await fn(tokenBound)
        }
        catch (err) {
            // 令牌过期
            if ('00000010' === err.code) {
                await waitForToken()

                // 已登出
                if (! token) {
                    throw new SuspenseError(err.message)
                }

                // 令牌未变化
                if (tokenBound === token.token) {
                    refreshToken()
                }

                // 重试
                return withToken(fn)
            }
            // 
            else if ('00000011' === err.code) {
                throw new SuspenseError(err.message)
            }
            // 单点登录
            else if ('00000090' === err.code) {
                // 如果有多个请求，只提示一次

                const shouldThrow = Boolean(token)
                logout()

                if (shouldThrow) {
                    throw err
                }
                else {
                    throw new SuspenseError(err.message)
                }
            }
            else {
                throw err
            }
        }
    }
    catch (err) {
        if (err instanceof RefreshTokenError) {
            logout()
            throw new SuspenseError(err.message)
        }
        else {
            throw err
        }
    }
}

const withTokenStream = async function*(fn) {
    try {
        await waitForToken()
        const tokenBound = token?.token

        try {
            yield* fn(tokenBound)
        }
        catch (err) {
            // 令牌过期
            if ('00000010' === err.code) {
                await waitForToken()

                // 已登出
                if (! token) {
                    throw new SuspenseError(err.message)
                }

                // 令牌未变化
                if (tokenBound === token.token) {
                    refreshToken()
                }

                // 重试
                yield* withTokenStream(fn)
            }
            // 
            else if ('00000011' === err.code) {
                throw new SuspenseError(err.message)
            }
            // 单点登录
            else if ('00000090' === err.code) {
                // 如果有多个请求，只提示一次

                const shouldThrow = Boolean(token)
                logout()

                if (shouldThrow) {
                    throw err
                }
                else {
                    throw new SuspenseError(err.message)
                }
            }
            else {
                throw err
            }
        }
    }
    catch (err) {
        if (err instanceof RefreshTokenError) {
            logout()
            throw new SuspenseError(err.message)
        }
        else {
            throw err
        }
    }
}

const requestWithToken = ({headers, ...options}) => {
    if (options.streaming) {
        return withTokenStream(
            token => requestStream({
                headers: {...headers, __TOKEN__: token},
                ...options,
            })
        )
    }
    else {
        return withToken(
            token => request({
                headers: {...headers, __TOKEN__: token},
                ...options
            })
        )
    }
}

const upload = (payload = {}, options = {}) => requestWithToken({
    headers: {
        ...options.headers,
        'Content-Type': 'multipart/form-data',
    },

    method: 'POST',

    payload: {
        contentType: payload.file?.type,
        ...payload,

        pathName: payload.pathName?.startsWith('/') ?
        payload.pathName : `/${payload.pathName}`,
    },

    url: '/files',
    ...options,
})

export default {
    upload,

    ...Object.fromEntries(
        ['delete', 'get', 'head', 'post', 'put'].map(
            method => [
                method,

                (url, payload, options = {}) => requestWithToken({
                    ...options,
                    method: method.toUpperCase(),
                    payload,
                    url,
                })
            ]
        )
    ),
}
