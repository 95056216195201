export default (path, params = {}) => {
    const url = new URL(path, window.location.origin)

    for (const [k, v] of Object.entries(params)) {
        if (
            undefined === k ||
            undefined === v ||
            null === k ||
            null === v ||
            '' === k ||
            '' === v
        ) {
            continue
        }

        if ('object' === typeof v) {
            url.searchParams.append(k, JSON.stringify(v))
        }
        else if (Array.isArray(v)) {
            for (const vv of v) {
                url.searchParams.append(k, vv)
            }
        }
        else {
            url.searchParams.append(k, v)
        }
    }

    return String(url)
}
