import {join} from 'hopedove-dom/url'

export default url => {
    if (! url) {
        return import.meta.env.VITE_HTTP_BASE_URL
    }

    if (! url.startsWith('/')) {
        return url
    }

    return join(import.meta.env.VITE_HTTP_BASE_URL, url)
}
