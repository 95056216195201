import {Select as AntdSelect} from 'antd'
import useCachedValue from '@/hooks/useCachedValue.mjs'
import InputText from './InputText.jsx'
import {useReadOnly} from './ReadOnly.mjs'
import stopPropagation from './stopPropagation.mjs'
import useSelectable from './useSelectable.mjs'

const Select = ({
    hottestFirst,
    mapOption,
    options,
    readOnly,
    value,
    onChange,
    ...props
}) => {
    const gReadOnly = useReadOnly()
    const [inputValue, setInputValue] = useCachedValue(value)

    const {
        antdOptions,
        filterOption,
        filterSort,
        handleChange,
    } = useSelectable({
        hottestFirst,
        mapOption,
        options,
        setInputValue,
        onChange,
    })

    const style = {
        display: 'block',
        width: '100%',
        color: 'inherit',
        overflow: 'hidden',
        ...props.style,
    }

    if (readOnly || gReadOnly) {
        const selectedOption = antdOptions.find(e => e.value === inputValue)
        const label = selectedOption?.label ?? ''

        return (
            <InputText
                readOnly
                value={label}
            />
        )
    }
    else {
        return (
            <AntdSelect
                filterOption={filterOption}
                filterSort={filterSort}
                optionLabelProp="writeBack"
                showSearch
                value={inputValue}
                onChange={handleChange}
                onClick={stopPropagation}
                options={antdOptions}
                {...props}
                style={style}
            />
        )
    }
}

export default Select
