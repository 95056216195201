import {useRef} from 'react'
import useControlledState from '@/hooks/useControlledState.mjs'

const useRowSelection = (options, rows, rowKey, iterRows) => {
    const refInitialSelected = useRef(false)
    const flatRows = [...iterRows(rows)]

    const rowMap = new Map(
        Array.from(flatRows).map(
            e => [e[rowKey], e]
        )
    )

    const [selectedRowKeys, setSelectedRowKeys] = useControlledState(
        [],
        options?.selectedRowKeys,

        keys => {
            const rows = keys.map(k => rowMap.get(k))
            options.onChange?.(keys, rows)
        }
    )

    if (! options) {
        return [null, setSelectedRowKeys]
    }

    if (
        ! refInitialSelected.current &&
        0 < flatRows.length &&
        options.defaultSelect
    ) {
        refInitialSelected.current = true
        const keys = options.defaultSelect(flatRows)
        setSelectedRowKeys(keys)
    }

    const cleanKeys = selectedRowKeys.filter(k => rowMap.has(k))

    const rowSelection = {
        columnWidth: 40,
        ...options,
        selectedRowKeys: cleanKeys,
        onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys)
    }

    return [rowSelection, setSelectedRowKeys]
}

export default useRowSelection
